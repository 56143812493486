<template>
  <ui-container>
    <ui-card>
      <h2>{{ getTitle }}</h2>

      <div class="row ui-form-row">
        <div class="col-4">
          <h4>{{ $t('CompanyProduct.Form.SelfieSettings.Title') }}</h4>
        </div>
        <div class="col-8">
          <h5>{{ $t('CompanyProduct.Form.SelfieSettings.BlockLabel') }}</h5>
          <ul class="checkbox-list">
            <li
              v-for="(selfieSetting, index) in documentSelfieSettingsSchema"
              :key="`selfie-setting-${index}`"
            >
              <ui-new-checkbox
                v-model="documentSelfieSettings"
                :label="selfieSetting.label"
                :value="selfieSetting.value"
              />
            </li>
          </ul>
        </div>
      </div>

      <div class="row ui-form-row">
        <div class="col-4">
          <h4>{{ $t('CompanyProduct.Form.DocumentType.Title') }}</h4>
        </div>
        <div class="col-8">
          <h5>{{ $t('CompanyProduct.Form.DocumentType.BlockLabel') }}</h5>
          <ul class="checkbox-list">
            <li
              v-for="(documentType, index) in documentTypesSchema"
              :key="`document-type-${index}`"
            >
              <ui-new-checkbox
                v-model="documentTypeModel"
                :label="documentType.label"
                :value="documentType.value"
              />

              <ul v-if="documentType.children">
                <li
                  v-for="(child, childIndex) in documentType.children"
                  :key="`document-type-child${childIndex}`"
                >
                  <ui-new-checkbox
                    v-if="
                      documentTypeModel.includes(DOCUMENT_TYPES.LOCAL_PASSPORT)
                    "
                    v-model="documentRules[child.group]"
                    :label="child.label"
                    :value="child.value"
                  />

                  <ul v-if="child.rules.length">
                    <li
                      v-for="(childRules, childRuleIndex) in child.rules"
                      :key="`document-type-child-rule${childRuleIndex}`"
                    >
                      <ui-new-checkbox
                        v-if="documentRules[child.group].includes(child.value)"
                        v-model="documentChildren[childRules.group]"
                        :label="childRules.label"
                        :value="childRules.value"
                      />

                      <ul
                        v-if="
                          childRules.value ===
                          APPLICATION_RULE.ADDITIONAL_COMMENTS
                        "
                      >
                        <li
                          v-if="
                            documentChildren[childRules.group].includes(
                              APPLICATION_RULE.ADDITIONAL_COMMENTS
                            )
                          "
                        >
                          <ui-textarea
                            v-model="additionalInfo[childRules.model]"
                            :placeholder="$t(childRules.placeholder)"
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul v-if="documentType.rules.length">
                <li
                  v-for="(rule, ruleIndex) in documentType.rules"
                  :key="`document-rules-${ruleIndex}`"
                >
                  <ui-new-checkbox
                    v-if="shouldDisplay(documentType.value, documentTypeModel)"
                    v-model="documentRules[rule.group]"
                    :disabled="rule.isDisabled"
                    :label="rule.label"
                    :value="rule.value"
                  />

                  <ul
                    v-if="
                      rule.value ===
                      APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED
                    "
                  >
                    <li
                      v-if="
                        shouldDisplay(
                          APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED,
                          documentRules[rule.group]
                        )
                      "
                    >
                      <listed-checkbox
                        :checkbox-values="rule.values"
                        class="listed-checkbox"
                        :list-items="countryList"
                        @valueChange="rule.method"
                      />
                    </li>
                  </ul>
                  <ul
                    v-if="rule.value === APPLICATION_RULE.ADDITIONAL_COMMENTS"
                  >
                    <li
                      v-if="
                        shouldDisplay(
                          APPLICATION_RULE.ADDITIONAL_COMMENTS,
                          documentRules[rule.group]
                        )
                      "
                    >
                      <ui-textarea
                        v-model="additionalInfo[rule.model]"
                        :placeholder="$t(rule.placeholder)"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="row ui-form-row">
        <div class="col-4">
          <h4>{{ $t('CompanyProduct.Form.SelectApplication.Title') }}</h4>
        </div>
        <div class="col-8">
          <h5>{{ $t('CompanyProduct.Form.SelectApplication.BlockLabel') }}</h5>
          <ul class="checkbox-list">
            <li
              v-for="(documentImportant, index) in documentImportantsSchema"
              :key="`document-important-${index}`"
            >
              <ui-new-checkbox
                v-model="documentImportants"
                :label="documentImportant.label"
                :value="documentImportant.value"
              />
              <ul
                v-if="
                  documentImportant.value ===
                  IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES
                "
              >
                <li>
                  <listed-checkbox
                    v-if="
                      shouldDisplay(
                        IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES,
                        documentImportants
                      )
                    "
                    :checkbox-values="prohibitedNationalities"
                    class="listed-checkbox listed-checkbox-importants"
                    :list-items="countryList"
                    @valueChange="setProhibitedNationalities"
                  />
                </li>
              </ul>
              <ul
                v-if="
                  documentImportant.value ===
                  IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES
                "
              >
                <li>
                  <listed-checkbox
                    v-if="
                      shouldDisplay(
                        IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES,
                        documentImportants
                      )
                    "
                    :checkbox-values="prohibitedCountries"
                    class="listed-checkbox listed-checkbox-importants"
                    :list-items="countryList"
                    @valueChange="setProhibitedCountries"
                  />
                </li>
              </ul>

              <ul
                v-if="
                  documentImportant.value ===
                  IMPORTANT_RULE.ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED
                "
              >
                <li>
                  <ui-textarea
                    v-if="
                      shouldDisplay(
                        IMPORTANT_RULE.ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED,
                        documentImportants
                      )
                    "
                    v-model="fieldsToCheck"
                    :placeholder="
                      $t(
                        'CompanyProduct.Form.SelectApplication.ChosenLabels.WriteDownFieldsToBeChecked'
                      )
                    "
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="row ui-form-row">
        <div class="col-4">
          <h4>{{ $t('CompanyProduct.Form.OtherComments.Title') }}</h4>
        </div>
        <div class="col-8">
          <h5>{{ $t('CompanyProduct.Form.OtherComments.BlockLabel') }}</h5>
          <div>
            <ul class="checkbox-list">
              <li>
                <ui-textarea
                  v-model="model.comment"
                  :placeholder="
                    $t(
                      'CompanyProduct.Form.OtherComments.WriteDownFieldsToBeChecked'
                    )
                  "
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-right">
          <ui-button
            class="btn-outline-brand border-radius-0"
            :disabled="isLoading"
            @click="$router.push({ name: 'CompanyProcedures' })"
          >
            {{ $t('AppCancel') }}
          </ui-button>
          <ui-button
            class="btn-brand border-radius-0"
            :loading="isLoading"
            :disabled="isLoading"
            @click="handleSubmit"
          >
            {{ $t('CompanyProduct.UpdateProcedures') }}
          </ui-button>
        </div>
      </div>
    </ui-card>
  </ui-container>
</template>

<script>
import ISOCountryCodes from '@src/assets/iso3.json'
import ListedCheckbox from '@src/components/ListedCheckbox'
import Api from '@src/scripts/api'
import {
  DOCUMENT_TYPES,
  APPLICATION_RULE,
  APPLICATION_COUNTRIES,
  IMPORTANT_RULE,
  SELFIE_TYPES,
  STATUS_CODE
} from '@src/scripts/enums'

export default {
  components: { ListedCheckbox },
  props: {
    type: { type: String, default: 'create' },
    model: {
      type: Object,
      default() {
        return {
          selfieTypes: [],
          acceptableDocuments: [],
          importants: [],
          comment: ''
        }
      }
    }
  },
  data: () => ({
    DOCUMENT_TYPES,
    IMPORTANT_RULE,
    APPLICATION_RULE,
    isLoading: false,
    documentTypeModel: [],
    documentItems: [],
    prohibitedCountries: [],
    prohibitedNationalities: [],
    driversLicenseCounties: [],
    residentPermitCounties: [],
    passportCounties: [],
    additionalInfo: {
      passportAdditionalComments: '',
      rusComments: '',
      ukrComments: '',
      idAdditionalComments: '',
      drivingLicenseComments: '',
      residencePermitComments: ''
    },
    idCounties: [],
    documentChildren: {
      rus: [],
      ukr: []
    },
    documentRules: {
      passportRules: [],
      drivingLicenseRules: [],
      residencePermitRules: [],
      idRules: [],
      localPassportGroup: []
    },
    fieldsToCheck: '',
    documentImportants: [],
    documentSelfieSettings: [],
    errors: { selfieSettings: null },
    documentSelfieSettingsSchema: [
      {
        label:
          'CompanyProduct.Form.SelfieSettings.ChosenLabels.MovingSelfieRequired',
        value: SELFIE_TYPES.MOVING_SELFIE_REQUIRED
      },
      {
        label:
          'CompanyProduct.Form.SelfieSettings.ChosenLabels.PhotoStandardNotFromDocument',
        value: SELFIE_TYPES.PHOTO_STANDARD_NOT_FROM_DOCUMENT
      },
      {
        label:
          'CompanyProduct.Form.SelfieSettings.ChosenLabels.PhotoStandardHoldingDocument',
        value: SELFIE_TYPES.PHOTO_STANDARD_HOLDING_DOCUMENT
      },
      {
        label: 'CompanyProduct.Form.SelfieSettings.ChosenLabels.NoRequired',
        value: SELFIE_TYPES.NO_REQUIRED
      }
    ],
    documentImportantsSchema: [
      {
        value: IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES,
        label:
          'CompanyProduct.Form.SelectApplication.ChosenLabels.HasProhibitedCountries'
      },
      {
        value: IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES,
        label:
          'CompanyProduct.Form.SelectApplication.ChosenLabels.HasProhibitedNationalities'
      },
      {
        value: IMPORTANT_RULE.ACCEPT_EXPIRATION_DATE_TODAY,
        label:
          'CompanyProduct.Form.SelectApplication.ChosenLabels.AcceptExpirationDateToday'
      },
      {
        value: IMPORTANT_RULE.NO_PRINT_SCREENS,
        label:
          'CompanyProduct.Form.SelectApplication.ChosenLabels.NoPrintscreens'
      },
      {
        value: IMPORTANT_RULE.NO_SCANNED_PICS_BAD_MEDIA_FORMAT,
        label:
          'CompanyProduct.Form.SelectApplication.ChosenLabels.NoScannedPicsBadMediaFormat'
      },
      {
        value: IMPORTANT_RULE.NO_SCANNED_PICS_FRAUD_ATTEMPT,
        label:
          'CompanyProduct.Form.SelectApplication.ChosenLabels.NoScannedPicsFraudAttempt'
      },
      {
        value: IMPORTANT_RULE.NO_BLACK_OR_WHITE_PICTURES,
        label:
          'CompanyProduct.Form.SelectApplication.ChosenLabels.NoBlackOrWhitePictures'
      },
      {
        value: IMPORTANT_RULE.ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED,
        label:
          'CompanyProduct.Form.SelectApplication.ChosenLabels.AdditionalRegistrationValidationRequired'
      }
    ]
  }),
  computed: {
    getTitle() {
      return this.type === 'create'
        ? this.$t('CompanyProduct.Form.CreateTitle')
        : this.$t('CompanyProduct.UpdateProcedures')
    },
    documentTypesSchema() {
      return [
        {
          value: DOCUMENT_TYPES.PASSPORT,
          label: 'CompanyProduct.Form.DocumentType.ChosenLabels.Passport',
          rules: [
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.PASSPORT
              ),
              group: 'passportRules',
              value: APPLICATION_RULE.MRZ_REQUIRED,
              label: 'CompanyProduct.Form.DocumentType.ChosenLabels.MrzRequired'
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.PASSPORT
              ),
              group: 'passportRules',
              value: APPLICATION_RULE.BACKSIDE_REQUIRED,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.BackSideRequired'
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.PASSPORT
              ),
              group: 'passportRules',
              value: APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.OnlySpecificCountriesAcceptable',
              method: this.setPassportCountries,
              values: this.passportCounties
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.PASSPORT
              ),
              group: 'passportRules',
              value: APPLICATION_RULE.ADDITIONAL_COMMENTS,
              label: 'CompanyProduct.Comment',
              placeholder: 'CompanyProduct.Comment',
              model: 'passportAdditionalComments'
            }
          ]
        },
        {
          value: DOCUMENT_TYPES.LOCAL_PASSPORT,
          label: 'Local Passport',
          children: [
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.LOCAL_PASSPORT
              ),
              group: 'localPassportGroup',
              value: APPLICATION_COUNTRIES.RUS,
              label: 'CompanyProduct.Form.DocumentType.ChosenLabels.Rus',
              rules: [
                {
                  group: 'rus',
                  value: APPLICATION_RULE.MRZ_REQUIRED,
                  label:
                    'CompanyProduct.Form.DocumentType.ChosenLabels.MrzRequired'
                },
                {
                  value: APPLICATION_RULE.ADDITIONAL_COMMENTS,
                  group: 'rus',
                  label: 'CompanyProduct.Comment',
                  placeholder: 'CompanyProduct.Comment',
                  model: 'rusComments'
                }
              ]
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.LOCAL_PASSPORT
              ),
              group: 'localPassportGroup',
              value: APPLICATION_COUNTRIES.UKR,
              label: 'CompanyProduct.Form.DocumentType.ChosenLabels.Ukr',
              rules: [
                {
                  group: 'ukr',
                  value: APPLICATION_RULE.MRZ_REQUIRED,
                  label:
                    'CompanyProduct.Form.DocumentType.ChosenLabels.MrzRequired'
                },
                {
                  value: APPLICATION_RULE.ADDITIONAL_COMMENTS,
                  label: 'CompanyProduct.Comment',
                  group: 'ukr',
                  placeholder: 'CompanyProduct.Comment',
                  model: 'ukrComments'
                }
              ]
            }
          ],
          rules: []
        },
        {
          value: DOCUMENT_TYPES.ID,
          label: 'CompanyProduct.Form.DocumentType.ChosenLabels.Id',
          rules: [
            {
              isDisabled: !this.documentTypeModel.includes(DOCUMENT_TYPES.ID),
              group: 'idRules',
              value: APPLICATION_RULE.MRZ_REQUIRED,
              label: 'CompanyProduct.Form.DocumentType.ChosenLabels.MrzRequired'
            },
            {
              isDisabled: !this.documentTypeModel.includes(DOCUMENT_TYPES.ID),
              group: 'idRules',
              value: APPLICATION_RULE.BOTH_SIDE_REQUIRED,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.BothSideRequired'
            },
            {
              isDisabled: !this.documentTypeModel.includes(DOCUMENT_TYPES.ID),
              group: 'idRules',
              value: APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.OnlySpecificCountriesAcceptable',
              method: this.setIdCountries,
              values: this.idCounties
            },
            {
              isDisabled: !this.documentTypeModel.includes(DOCUMENT_TYPES.ID),
              group: 'idRules',
              value: APPLICATION_RULE.ADDITIONAL_COMMENTS,
              label: 'CompanyProduct.Comment',
              placeholder: 'CompanyProduct.Comment',
              model: 'idAdditionalComments'
            }
          ]
        },
        {
          value: DOCUMENT_TYPES.DRIVING_LICENSE,
          label: 'CompanyProduct.Form.DocumentType.ChosenLabels.DrivingLicense',
          rules: [
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.DRIVING_LICENSE
              ),
              group: 'drivingLicenseRules',
              value: APPLICATION_RULE.MRZ_REQUIRED,
              label: 'CompanyProduct.Form.DocumentType.ChosenLabels.MrzRequired'
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.DRIVING_LICENSE
              ),
              group: 'drivingLicenseRules',
              value: APPLICATION_RULE.BOTH_SIDE_REQUIRED,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.BothSideRequired'
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.DRIVING_LICENSE
              ),
              group: 'drivingLicenseRules',
              value: APPLICATION_RULE.PROVISION_OR_LEARNER_DL_ACCEPTED,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.ProvisionalOrLearnerDlAccepted'
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.DRIVING_LICENSE
              ),
              group: 'drivingLicenseRules',
              value: APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.OnlySpecificCountriesAcceptable',
              method: this.setDriversLicenseCountries,
              values: this.driversLicenseCounties
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.DRIVING_LICENSE
              ),
              group: 'drivingLicenseRules',
              value: APPLICATION_RULE.ADDITIONAL_COMMENTS,
              label: 'CompanyProduct.Comment',
              placeholder: 'CompanyProduct.Comment',
              model: 'drivingLicenseComments'
            }
          ]
        },
        {
          value: DOCUMENT_TYPES.RESIDENCE_PERMIT,
          label:
            'CompanyProduct.Form.DocumentType.ChosenLabels.ResidencePermit',
          rules: [
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.RESIDENCE_PERMIT
              ),
              group: 'residencePermitRules',
              value: APPLICATION_RULE.ACCEPT_PERMANENT,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.AcceptPermanent'
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.RESIDENCE_PERMIT
              ),
              group: 'residencePermitRules',
              value: APPLICATION_RULE.ACCEPT_TEMPORARY,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.AcceptTemporary'
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.RESIDENCE_PERMIT
              ),
              group: 'residencePermitRules',
              value: APPLICATION_RULE.BOTH_SIDE_REQUIRED,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.BothSideRequired'
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.RESIDENCE_PERMIT
              ),
              group: 'residencePermitRules',
              value: APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED,
              label:
                'CompanyProduct.Form.DocumentType.ChosenLabels.OnlySpecificCountriesAcceptable',
              method: this.setResidentPermitCounties,
              values: this.residentPermitCounties
            },
            {
              isDisabled: !this.documentTypeModel.includes(
                DOCUMENT_TYPES.RESIDENCE_PERMIT
              ),
              group: 'residencePermitRules',
              value: APPLICATION_RULE.ADDITIONAL_COMMENTS,
              label: 'CompanyProduct.Comment',
              placeholder: 'CompanyProduct.Comment',
              model: 'residencePermitComments'
            }
          ]
        }
      ]
    },
    countryList() {
      return Object.keys({ ...ISOCountryCodes }).map((item) => ({
        label: `${this.$t(`Country.${item}`)}(${item})`,
        value: item
      }))
    }
  },
  watch: {
    '$store.state.selectedCompany': function () {
      this.$router.push({ name: 'CompanyProcedures' })
    },
    'documentRules.idRules': function (value) {
      if (!value.includes(APPLICATION_RULE.ADDITIONAL_COMMENTS)) {
        this.additionalInfo.idAdditionalComments = ''
      }

      if (!value.includes(APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED)) {
        this.idCounties = []
      }
    },
    'documentChildren.rus': function (value) {
      if (!value.includes(APPLICATION_RULE.ADDITIONAL_COMMENTS)) {
        this.additionalInfo.rusComments = ''
      }
    },
    'documentChildren.ukr': function (value) {
      if (!value.includes(APPLICATION_RULE.ADDITIONAL_COMMENTS)) {
        this.additionalInfo.ukrComments = ''
      }
    },
    'documentRules.passportRules': function (value) {
      if (!value.includes(APPLICATION_RULE.ADDITIONAL_COMMENTS)) {
        this.additionalInfo.passportAdditionalComments = ''
      }

      if (!value.includes(APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED)) {
        this.passportCounties = []
      }
    },
    'documentRules.residencePermitRules': function (value) {
      if (!value.includes(APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED)) {
        this.residentPermitCounties = []
      }

      if (!value.includes(APPLICATION_RULE.ADDITIONAL_COMMENTS)) {
        this.additionalInfo.residencePermitComments = ''
      }
    },
    'documentRules.drivingLicenseRules': function (value) {
      if (!value.includes(APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED)) {
        this.driversLicenseCounties = []
      }

      if (!value.includes(APPLICATION_RULE.ADDITIONAL_COMMENTS)) {
        this.additionalInfo.drivingLicenseComments = ''
      }
    },
    'documentRules.localPassportGroup': function (value) {
      if (!value.includes(APPLICATION_COUNTRIES.RUS)) {
        this.documentChildren.rus = []
      }
      if (!value.includes(APPLICATION_COUNTRIES.UKR)) {
        this.documentChildren.ukr = []
      }
    },
    documentImportants(value) {
      if (!value.includes(IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES)) {
        this.prohibitedCountries = []
      }

      if (!value.includes(IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES)) {
        this.prohibitedNationalities = []
      }
    },
    documentTypeModel(value) {
      if (!value.includes(DOCUMENT_TYPES.RESIDENCE_PERMIT)) {
        this.documentRules.residencePermitRules = []
        this.residentPermitCounties = []
      }

      if (!value.includes(DOCUMENT_TYPES.ID)) {
        this.documentRules.idRules = []
        this.idCounties = []
      }

      if (!value.includes(DOCUMENT_TYPES.DRIVING_LICENSE)) {
        this.documentRules.drivingLicenseRules = []
        this.driversLicenseCounties = []
      }

      if (!value.includes(DOCUMENT_TYPES.PASSPORT)) {
        this.documentRules.passportRules = []
        this.passportCounties = []
      }

      if (!value.includes(DOCUMENT_TYPES.LOCAL_PASSPORT)) {
        this.documentRules.localPassportGroup = []
      }
    }
  },
  created() {
    this.documentSelfieSettings = this.model.selfieTypes
    this.model.acceptableDocuments.map((document) => {
      this.documentTypeModel.push(document.documentType)
      if (document.documentType === DOCUMENT_TYPES.PASSPORT) {
        this.documentRules.passportRules = document.applicableRules
        this.passportCounties = document.countriesIso2

        if (document.additionalInfo) {
          if (
            document.additionalInfo.comment !== '' ||
            document.additionalInfo.comment
          ) {
            this.documentRules.passportRules.push(
              APPLICATION_RULE.ADDITIONAL_COMMENTS
            )
            this.additionalInfo.passportAdditionalComments =
              document.additionalInfo.comment
          }
        }
      } else if (document.documentType === DOCUMENT_TYPES.DRIVING_LICENSE) {
        this.documentRules.drivingLicenseRules = document.applicableRules
        this.driversLicenseCounties = document.countriesIso2
        if (document.additionalInfo) {
          if (
            document.additionalInfo.comment !== '' ||
            document.additionalInfo.comment
          ) {
            this.documentRules.drivingLicenseRules.push(
              APPLICATION_RULE.ADDITIONAL_COMMENTS
            )
            this.additionalInfo.drivingLicenseComments =
              document.additionalInfo.comment
          }
        }
      } else if (document.documentType === DOCUMENT_TYPES.LOCAL_PASSPORT) {
        document.items.map((item) => {
          this.documentRules.localPassportGroup.push(item.countryIso2)
          this.documentChildren[item.countryIso2.toLowerCase()] =
            item.applicableRules

          if (item.additionalInfo.comment !== '') {
            this.documentChildren[item.countryIso2.toLowerCase()].push(
              APPLICATION_RULE.ADDITIONAL_COMMENTS
            )

            if (item.countryIso2 === APPLICATION_COUNTRIES.RUS) {
              this.additionalInfo.rusComments = item.additionalInfo.comment
            } else if (item.countryIso2 === APPLICATION_COUNTRIES.UKR) {
              this.additionalInfo.ukrComments = item.additionalInfo.comment
            }
          }
        })
      } else if (document.documentType === DOCUMENT_TYPES.ID) {
        this.documentRules.idRules = document.applicableRules
        this.idCounties = document.countriesIso2
        if (document.additionalInfo) {
          if (
            document.additionalInfo.comment !== '' ||
            document.additionalInfo.comment
          ) {
            this.documentRules.idRules.push(
              APPLICATION_RULE.ADDITIONAL_COMMENTS
            )
            this.additionalInfo.idAdditionalComments =
              document.additionalInfo.comment
          }
        }
      } else if (document.documentType === DOCUMENT_TYPES.RESIDENCE_PERMIT) {
        this.documentRules.residencePermitRules = document.applicableRules
        this.residentPermitCounties = document.countriesIso2
        if (document.additionalInfo) {
          if (
            document.additionalInfo.comment !== '' ||
            document.additionalInfo.comment
          ) {
            this.documentRules.residencePermitRules.push(
              APPLICATION_RULE.ADDITIONAL_COMMENTS
            )
            this.additionalInfo.residencePermitComments =
              document.additionalInfo.comment
          }
        }
      }
    })

    this.model.importants.map((important) => {
      this.documentImportants.push(important.rule)
      if (important.rule === IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES) {
        this.prohibitedCountries = important.countriesIso2
      } else if (
        important.rule === IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES
      ) {
        this.prohibitedNationalities = important.countriesIso2
      } else if (
        important.rule ===
        IMPORTANT_RULE.ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED
      ) {
        this.fieldsToCheck = important.additionalRegistrationValidationRule
      }
    })
  },
  methods: {
    shouldDisplay(value, list) {
      return list.includes(value)
    },
    getImportant() {
      this.model.importants = this.documentImportants.map((important) => {
        if (important === IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES) {
          return {
            rule: important,
            countriesIso2: this.prohibitedCountries.length
              ? this.prohibitedCountries
              : []
          }
        } else if (important === IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES) {
          return {
            rule: important,
            countriesIso2: this.prohibitedNationalities.length
              ? this.prohibitedNationalities
              : []
          }
        } else if (
          important ===
          IMPORTANT_RULE.ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED
        ) {
          return {
            rule: important,
            additionalRegistrationValidationRule: this.fieldsToCheck
          }
        } else {
          return {
            rule: important
          }
        }
      })
    },
    getDocumentData() {
      this.model.selfieTypes = this.documentSelfieSettings
      this.model.acceptableDocuments = this.documentTypeModel.map((type) => {
        if (type === DOCUMENT_TYPES.PASSPORT) {
          return {
            documentType: type,
            applicableRules: this.documentRules.passportRules.filter(
              (item) => item !== APPLICATION_RULE.ADDITIONAL_COMMENTS
            ),
            countriesIso2: this.passportCounties.length
              ? this.passportCounties
              : [],
            additionalInfo: {
              comment: this.additionalInfo.passportAdditionalComments
            }
          }
        } else if (type === DOCUMENT_TYPES.DRIVING_LICENSE) {
          return {
            documentType: type,
            applicableRules: this.documentRules.drivingLicenseRules.filter(
              (item) => item !== APPLICATION_RULE.ADDITIONAL_COMMENTS
            ),
            countriesIso2: this.driversLicenseCounties.length
              ? this.driversLicenseCounties
              : [],
            additionalInfo: {
              comment: this.additionalInfo.drivingLicenseComments
            }
          }
        } else if (type === DOCUMENT_TYPES.ID) {
          return {
            documentType: type,
            applicableRules: this.documentRules.idRules.filter(
              (item) => item !== APPLICATION_RULE.ADDITIONAL_COMMENTS
            ),
            countriesIso2: this.idCounties.length ? this.idCounties : [],
            additionalInfo: {
              comment: this.additionalInfo.idAdditionalComments
            }
          }
        } else if (type === DOCUMENT_TYPES.RESIDENCE_PERMIT) {
          return {
            documentType: type,
            applicableRules: this.documentRules.residencePermitRules.filter(
              (item) => item !== APPLICATION_RULE.ADDITIONAL_COMMENTS
            ),
            countriesIso2: this.residentPermitCounties.length
              ? this.residentPermitCounties
              : [],
            additionalInfo: {
              comment: this.additionalInfo.residencePermitComments
            }
          }
        } else if (type === DOCUMENT_TYPES.LOCAL_PASSPORT) {
          return {
            documentType: type,
            items: this.documentRules.localPassportGroup.map((item) => {
              return {
                countryIso2: item,
                applicableRules: this.documentChildren[
                  item.toLowerCase()
                ].filter(
                  (item) => item !== APPLICATION_RULE.ADDITIONAL_COMMENTS
                ),
                additionalInfo: {
                  comment:
                    item === APPLICATION_COUNTRIES.RUS
                      ? this.additionalInfo.rusComments
                      : this.additionalInfo.ukrComments
                }
              }
            })
          }
        } else {
          return {
            documentType: type
          }
        }
      })
    },
    setDriversLicenseCountries(value) {
      this.driversLicenseCounties = value
    },
    setResidentPermitCounties(value) {
      this.residentPermitCounties = value
    },
    setPassportCountries(value) {
      this.passportCounties = value
    },
    setIdCountries(value) {
      this.idCounties = value
    },
    setProhibitedCountries(value) {
      this.prohibitedCountries = value
    },
    setProhibitedNationalities(value) {
      this.prohibitedNationalities = value
    },
    async handleSubmit() {
      this.getDocumentData()
      this.getImportant()

      this.isLoading = true
      try {
        this.type === 'create'
          ? await this.createForm(this.model)
          : await this.updateForm(this.model)
        this.isLoading = false
        this.$router.push({ name: 'CompanyProcedures' })
      } catch (error) {
        this.isLoading = false
        if (error === STATUS_CODE.CREATED || error === STATUS_CODE.NOT_FOUND) {
          this.$router.push({ name: 'CompanyProcedures' })
        } else {
          this.$notify({
            title: 'ErrorUnknownTitle',
            message: 'ErrorUnknownDescription'
          })
        }
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    },
    updateForm(payload) {
      const id = this.$route.query.id
      return Api.put(`/identification-procedures/${id}`, payload)
    },
    createForm(payload) {
      return Api.post('/identification-procedures', payload)
    }
  }
}
</script>

<style scoped>
/deep/ .btn-content {
  flex-direction: row;
}
h2 {
  font-size: 1.769rem;
  line-height: 1.769rem;
  color: var(--ligh-dark);
  padding-bottom: 4rem;
  font-weight: 600;
}

h4 {
  font-size: 1.308rem;
  line-height: 1.308rem;
}

h5 {
  font-size: 1.07rem;
  line-height: 1.07rem;
  padding-bottom: 1.07rem;
}

h4,
h5 {
  color: var(--dark);
  font-weight: 600;
}

.border-radius-0 {
  border-radius: 0;
}

.ui-form-row {
  margin-bottom: 5.692rem;
}

.checkbox-list {
  list-style: none;
  padding: 0;
}

.checkbox-list > li /deep/ label div {
  color: var(--dark);
  font-family: sans-serif;
}

.checkbox-list > li ul li /deep/ label div {
  font-weight: 400;
}

.checkbox-list ul {
  list-style: none;
}

.listed-checkbox {
  margin-left: 2.308rem;
}

.listed-checkbox-importants {
  margin-left: 0;
}
</style>
