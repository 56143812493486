<template>
  <div class="m-list-timeline__item rule-item" :class="{ 'icon-top': reasons }">
    <i :class="statusIcon" class="rule-icon" />
    <span class="hover-text">{{ $t(statusIconText) }}</span>
    <span class="m-list-timeline__text">
      {{ $t(statusName) }}<span v-if="reasons">:</span>
      <span v-if="reason" class="m-list-timeline__text reason-text">
        {{ reasonText }}
      </span>
      <ul v-if="reasons" class="m-list-timeline__text reason-text">
        <li v-for="(item, key) in reasons" :key="key">
          {{ $t(`REASON_${item}`) }}
        </li>
      </ul>
    </span>
    <ui-button
      v-if="action"
      class="btn btn-info"
      small
      low
      small-text
      @click="action"
    >
      {{ $t('Identification.Rules.Actions.Details') }}
    </ui-button>
  </div>
</template>

<script>
import { statusTypesEnum } from '@src/scripts/enums'
import { exceptionReasons, exceptionRules } from '@src/scripts/constants'

export default {
  props: {
    name: { type: [String, Number], default: null },
    status: { type: String, default: null },
    reason: { type: String, default: null },
    reasons: { type: Array, default: null },
    action: { type: Function, default: null },
    noStatus: Boolean,
    type: { type: String, default: undefined }
  },

  computed: {
    isInExceptionList() {
      return (
        this.reason &&
        exceptionReasons.includes(this.reason) &&
        exceptionRules.includes(this.name)
      )
    },

    statusName() {
      const { status, name, noStatus, type } = this
      console.log('??????', { status, name, noStatus, type })
      if (!noStatus) {
        if (!name || typeof name === 'number') return name
        if (status === statusTypesEnum.succeeded) return `${name}_SUCCESS`
        else if (status === statusTypesEnum.failed) {
          if (type === 'rules' && !['REGISTRATION_DATA_CHECK'].includes(name)) {
            return ''
          }
          return `${name}_FAIL`
        } else {
          if (
            ['PEP', 'SANCTIONS_SCREENING', 'REGISTRATION_DATA_CHECK'].includes(
              name
            ) &&
            status === statusTypesEnum.unavailable
          ) {
            return `${name}_UNAVAILABLE`
          }

          return name
        }
      } else return name
    },

    statusIcon() {
      const { status } = this
      if (status === statusTypesEnum.succeeded)
        return 'flaticon-interface-7 m--font-success'
      else if (status === statusTypesEnum.unavailable)
        return 'flaticon-warning-2 m--font-warning'
      else return 'la la-times-circle m--font-danger'
    },

    statusIconText() {
      const { status } = this
      if (status === statusTypesEnum.succeeded) return 'statusIconTextSuccess'
      else if (status === statusTypesEnum.unavailable)
        return 'statusIconTextWarning'
      else return 'statusIconTextDanger'
    },

    failReasonText() {
      return this.isInExceptionList
        ? this.$t(`RULE_REASON_${this.reason}_${this.name}`)
        : this.$t(`RULE_REASON_${this.reason}`)
    },

    reasonText() {
      if (!this.noStatus) {
        if (this.$t(`RULE_REASON_${this.reason}`))
          return this.type === 'rules'
            ? this.failReasonText
            : `- ${this.$t(`RULE_REASON_${this.reason}`)}`
        else return ''
      } else {
        if (this.$t(`REASON_${this.reason}`))
          return `- ${this.$t(`REASON_${this.reason}`)}`
        else return ''
      }
    }
  }
}
</script>

<style scoped>
.rule-item {
  display: flex;
  min-height: 2rem;
  position: relative;
}

.rule-item > i,
.rule-item > span {
  margin-top: auto;
  margin-bottom: auto;
}

.rule-item > i {
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 1rem;
}

.rule-item > i::before {
  display: block;
}

.ui-button {
  margin: 0.5rem 0 0.5rem auto;
}

.hover-text {
  display: none;
  position: absolute;
  left: 1.5rem;
  top: -0.5rem;
  background: #eee;
  border: 1px solid #ccc;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
}

.rule-icon:hover ~ .hover-text {
  display: block;
}

.icon-top ul.reason-text {
  padding-left: 1.25rem;
}

.icon-top .rule-icon {
  margin-top: 0.25rem;
}
</style>
