const widgetTypes = {
  name: 'widgetType',
  type: {
    itemGrid: 'item',
    pieChart: 'pie',
    barChart: 'bar',
    lineChart: 'line'
  }
}

const chartTypes = {
  name: 'chartTypes',
  type: {
    pie: 'pie',
    doughnut: 'doughnut',
    basic: 'basic',
    stacked: 'stacked'
  }
}

const valueTypes = {
  name: 'valueTypes',
  type: {
    full: 'full',
    top: 'top'
  }
}

const statusTypes = {
  name: 'statusTypes',
  type: {
    succeeded: 'SUCCESS',
    failed: 'FAIL',
    unavailable: 'UNAVAILABLE'
  }
}

const iconTypes = {
  name: 'iconTypes',
  type: {
    users: 'flaticon-users',
    checkBox: 'flaticon-interface-7',
    close: 'flaticon-close',
    refresh: 'flaticon-refresh',
    stopwatch: 'flaticon-stopwatch',
    coins: 'flaticon-coins',
    settings: 'flaticon-cogwheel-2',
    edit: 'flaticon-edit-1'
  }
}

const pageTypes = {
  name: 'pageTypes',
  type: {
    dashboard: 'Dashboard',
    identifications: 'Identifications',
    webhookHistory: 'WebhookHistory',
    companyProcedures: 'Admin_Companies_Procedures',
    agentUrls: 'AgentUrls',
    createProcedures: 'Admin_Companies_Procedures',
    updateProcedures: 'Admin_Companies_Procedures',
    webhooksSearch: 'WebhookHistory',
    webhooks: 'WebhookHistory',
    sanctions: 'SanctionsScreening',
    sanctionsSearch: 'SanctionsScreening',
    sanctionDetails: 'SanctionsScreening',
    sanctionsScreening: 'Sanctions',
    secureMeetings: 'SecureMeetings',
    identificationsSearch: 'Identifications',
    identificationDetails: 'Identifications_Details',
    stopList: 'StopList',
    videoCall: 'VideoCall',
    callSettings: 'VideoCall_Settings',
    admin: 'Admin',
    adminUsers: 'Admin_Users',
    adminUserDetails: 'Admin_Users_Details',
    adminCompanies: 'Admin_Companies',
    adminCompaniesDetails: 'Admin_Companies_Details',
    adminCompanyDetails: 'Admin_Companies_Details',
    kyb: 'BusinessScreening',
    app: 'App',
    psd2: 'Psd2',
    psdMyApps: 'PsdMyApps',
    psdDashboard: 'PsdDashboard',
    psdDocuments: 'PsdDocuments',
    billingSettings: 'BillingSettings',
    userSettings: 'UserSettings',
    checkSettings: 'CheckSettings',
    sharedDatabase: 'SharedDatabase',
    adminUserActivity: 'AdminUserActivity',
    kycSettings: 'KycSettings',
    kybPage: 'BusinessScreening',
    kybCourts: 'BusinessScreening_Courts', // Business screening courts tab
    kybTransport: 'BusinessScreening_Transport', // Business screening courts tab
    kybSeizures: 'BusinessScreening_Seizures', // Business screening seizures tab
    kybRelations: 'BusinessScreening_Relations', // Business screening relations tab
    kybUbo: 'BusinessScreening_Ubo', // Business screening ubo tab
    kybJadis: 'BusinessScreening_Jadis', // Business screening > Statements > Business registry > Statement of participants in the legal entity
    kybJarCompanyReport: 'BusinessScreening_Jar_Company_Report', // Business screening > Statements > Business registry > Extended statement with history of legal entity
    kybJarFinancialReport: 'BusinessScreening_Jar_Financial_Report', // Business screening > Statements > Financial statements
    kybJangisFinancialReport: 'BusinessScreening_Jangis',
    kybScorify: 'BusinessScreening_Scorify', // Business screening > Statements > Other documents > Scorify business report
    businessScreening: 'Kyb',
    companyList: 'CompanyList',
    legalEntityMonitoringEvents: 'LegalEntityMonitoringEvents',
    legalEntityMonitoringSetup: 'LegalEntityMonitoringSetup',
    kybPageDetails: 'BusinessScreening',
    kybCompanyList: 'BusinessScreening',
    emptyRouter: 'EmptyRouter',
    pdfPage: 'Identifications',
    manualCheck: 'ManualCheck',
    kybIdentifications: 'KybIdentifications',
    kybIdentificationsDetails: 'KybIdentifications_Details',
    naturalEntityMonitoringList: 'NaturalEntityMonitoringList',
    naturalEntityMonitoringEvents: 'NaturalEntityMonitoringList',
    identificationDashboard: 'ArbitriumRealTimeDashboard',
    finishedIdentifications: 'ArbitriumRealTimeDashboard',
    currentIdentifications: 'ArbitriumRealTimeDashboard',
    companyReportsPage: 'KybIdentifications'
  }
}

const callAgentStatusTypes = {
  name: 'pageTypes',
  type: {
    available: 'AVAILABLE',
    unavailable: 'UNAVAILABLE',
    offline: 'OFFLINE'
  }
}

const enumHandler = {
  get(target, key) {
    if (typeof key === 'symbol' || key === '_isVue') return target.type
    const tempKey = key.charAt(0).toLowerCase() + key.slice(1)
    if (target.type[tempKey]) return target.type[tempKey]
    throw `No key: ${tempKey} in ${target.name}.`
  },

  set(target, key) {
    throw `Can't edit ${target.name}. Key: ${key}.`
  }
}

export const widgetTypeEnum = new Proxy(widgetTypes, enumHandler)
export const chartTypeEnum = new Proxy(chartTypes, enumHandler)
export const valueTypeEnum = new Proxy(valueTypes, enumHandler)
export const iconTypeEnum = new Proxy(iconTypes, enumHandler)
export const pageTypeEnum = new Proxy(pageTypes, enumHandler)
export const statusTypesEnum = new Proxy(statusTypes, enumHandler)
export const callAgentStatusTypesEnum = new Proxy(
  callAgentStatusTypes,
  enumHandler
)

export const searchStatuses = Object.freeze({
  ready: 'Ready',
  loading: 'Loading',
  error: 'Error'
})

export const kybReviewStatuses = Object.freeze({
  all: 'All',
  approved: 'Approved',
  pending: 'Pending',
  rejected: 'Rejected'
})

export const kycRequestStatuses = Object.freeze({
  pending: 'PENDING',
  rejected: 'REJECTED'
})

export const reviewStatuses = Object.freeze({
  approved: 'Approved',
  pending: 'Pending',
  rejected: 'Rejected'
})

export const representativeStatuses = Object.freeze({
  approved: 'Approved',
  pending: 'Pending',
  rejected: 'Rejected',
  externallyVerified: 'ExternallyVerified'
})

export const uboLoadMoreList = Object.freeze({
  LAT: 'LAT',
  LVA: 'LVA',
  EST: 'EST',
  LV: 'LV'
})

export const uboLoadMoreListIso2 = Object.freeze({
  LV: 'LV',
  ES: 'ES',
  LT: 'LT',
  EE: 'EE'
})

export const iso2Iso3Counties = {
  LAT: 'LT',
  LVA: 'LV',
  EST: 'EE',
  LTU: 'LT'
}

export const API = Object.freeze({
  KYB_GENERIC_UBO: '/kyb/ultimate-beneficial-owners',
  MONITORING_COMPANIES: '/legal-entity-monitoring/companies',
  MONITORING_EVENTS: '/legal-entity-monitoring/events',
  FORGOT_PASSWORD: '/users/forgot-password',
  RESET_PASSWORD: '/users/reset-password',
  USER_SETTINGS: '/users/settings',
  USER_DETAILS: '/users/details/',
  NATURAL_MONITORING_PERSONS: '/natural-entity-monitoring/persons',
  NATURAL_MONITORNG_EVENTS: '/natural-entity-monitoring/events',
  NATURAL_MONITORNG_REGISTRIES: '/natural-entity-monitoring/registries',
  IDENTIFICATIONS_START_SESSION: '/identifications/start',
  IDENTIFICATION_DETAILS: (id) => `/identifications/${id}/details`,
  AML_DETAILS: (id) => `/natural-entity-monitoring/events/${id}/details/aml`,
  REALTIME_IDENT_USERS: (limit, offset) =>
    `/arbitrium-realtime/connected-users?limit=${limit}&offset=${offset}&orderBy=CreatedUtc&orderDirection=Desc`,
  REALTIME_IDENTIFICATIONS: (limit, offset) =>
    `/arbitrium-realtime/identifications?limit=${limit}&offset=${offset}&orderBy=CreatedUtc&orderDirection=Desc`,
  REALTIME_EXPORT: (limit, offset) =>
    `/arbitrium-realtime/identifications/download/csv?limit=${limit}&offset=${offset}&orderBy=CreatedUtc&orderDirection=Desc`,
  SETTINGS_KYC: '/settings/kyc',
  IDENTIFICATION_SEARCH: '/identifications/search',
  UPDATE_IDENTIFICATION_POA: '/identifications/update-poa'
})

export const ROLES = Object.freeze({
  SUPPER_ADMIN: 'SuperAdmin'
})

export const exportMethodTypes = Object.freeze({
  thirdPartyExport: 'ThirdPartyExport'
})

export const MONITORING_DOCUMENT_TYPES = {
  PASSPORT: 'Passport',
  ID_CARD: 'IdentityCard',
  RESIDENCE_PERMIT: 'ResidencePermit',
  DRIVER_LICENSE: 'DriverLicense',
  INTERNAL_PASSPORT: 'LocalPassport',
  SOCIAL_ID: 'SocialId',
  OTHER: 'Other'
}

export const DOCUMENT_TYPES = {
  PASSPORT: 'Passport',
  ID: 'Id',
  DRIVING_LICENSE: 'DrivingLicense',
  RUS_LOCAL_PASPORT_PLUS_MRZ: 'RusLocalPasportPlusMrz',
  RESIDENCE_PERMIT: 'ResidencePermit',
  DRIVER_LICENSE: 'DriverLicense',
  ID_CARD: 'IdentityCard',
  SOCIAL_ID: 'SocialId',
  INTERNAL_PASSPORT: 'LocalPassport',
  LOCAL_PASSPORT: 'LocalPassport',
  OTHER: 'Other'
}

export const APPLICATION_COUNTRIES = {
  RUS: 'RUS',
  UKR: 'UKR'
}

export const APPLICATION_RULE = {
  MRZ_REQUIRED: 'MrzRequired',
  BACKSIDE_REQUIRED: 'BackSideRequired',
  ONLY_SPECIFIC_COUNTIES_ACCEPTED: 'OnlySpecificCountriesAcceptable',
  BOTH_SIDE_REQUIRED: 'BothSideRequired',
  PROVISION_OR_LEARNER_DL_ACCEPTED: 'ProvisionalOrLearnerDlAccepted',
  ACCEPT_PERMANENT: 'AcceptPermanent',
  ACCEPT_TEMPORARY: 'AcceptTemporary',
  ADDITIONAL_COMMENTS: 'additionalInfo',
  LOCAL_PASSPORTS: 'LocalPassports'
}

export const IMPORTANT_RULE = {
  HAS_PROHIBITED_COUNTRIES: 'HasProhibitedCountries',
  HAS_PROHIBITED_NATIONALITIES: 'HasProhibitedNationalities',
  ACCEPT_EXPIRATION_DATE_TODAY: 'AcceptExpirationDateToday',
  NO_SCANNED_PICS: 'NoScannedPics',
  NO_PRINT_SCREENS: 'NoPrintscreens',
  NO_SCREEN_CAPTURE: 'NoScreenCapture',
  NO_BLACK_OR_WHITE_PICTURES: 'NoBlackOrWhitePictures',
  ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED:
    'AdditionalRegistrationValidationRequired',
  NO_SCANNED_PICS_BAD_MEDIA_FORMAT: 'NoScannedPicsBadMediaFormat',
  NO_SCANNED_PICS_FRAUD_ATTEMPT: 'NoScannedPicsFraudAttempt'
}

export const SELFIE_TYPES = {
  MOVING_SELFIE_REQUIRED: 'MovingSelfieRequired',
  PHOTO_STANDARD_NOT_FROM_DOCUMENT: 'PhotoStandardNotFromDocument',
  PHOTO_STANDARD_HOLDING_DOCUMENT: 'PhotoStandardHoldingDocument',
  NO_REQUIRED: 'NoRequired'
}

export const STATUS_CODE = {
  NOT_FOUND: 404,
  CREATED: 201,
  NO_DATA: 204
}

export const PRODUCTS = {
  BUSINESS_SCREENING_LV: 'BusinessScreeningLv',
  BUSINESS_SCREENING_EE: 'BusinessScreeningEe',
  NATURAL_ENTITY_MONITORING: 'NaturalEntityMonitoring'
}

export const REQUEST_STATUS = {
  PASSED_MANUAL: 'PASSED_MANUAL',
  PASSED_AUTO: 'PASSED_AUTO',
  PENDING: 'PENDING'
}

export const REVIEW_STATUS = {
  CROSS_CHECKED: 'CROSS_CHECKED',
  NOT_CROSS_CHECKED: 'NOT_CROSS_CHECKED'
}

export const MONITORING_CHANGE_STATUS = {
  LOADING: 'Loading',
  SUCCESS: 'Success',
  ERROR: 'Error'
}

export const MONITORING_STATUS = {
  IS_MONITORED: 'IsMonitored',
  NOT_MONITORED: 'NotMonitored'
}

export const IDENTIFICATION_DASHBOARD_TABS = {
  ACTIVE_USERS_TAB: 'activeUsersTab',
  PENDING_IDENTIFICATIONS_TAB: 'pendingIdentificationsTab',
  BY_USER_TAB: 'byUserTab',
  BY_COMPANY_TAB: 'byCompanyTab'
}

export const IDENTIFICATION_STATUS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'InProgress',
  FINISHED: 'Finished'
}

export const COUNTRIES_WITH_STATE = {
  USA: 'USA',
  CAN: 'CAN',
  MEX: 'MEX'
}
